import authAxios from "../../../../helpers/authAxios";
import { useDispatch } from 'react-redux';
import { Col, Row, Table, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getDescriptorsList } from '../../../../api/filter';
import { getSupplierStats } from '../../../../api/retailers'
import { getCountries } from '../../../../api/filter';
import { createDraftItem, editDraftItem } from '../../../../api/items';
import { useActions } from '../../../../hooks/useActions';
import { getSizes } from '../../../../api/filter/index';
import { convertFilesForRequest } from "../../../../helpers";
import { useLocation } from "react-router-dom";
import {ProductFieldsDictionary} from "../../../common/constants/products";
import ProductHierarchy from '../../../common/components/ProductHierarchy';
import { IconInfoHolder } from './IconInfoHolderSupplier';
import styles from './summarySupplier.module.scss'

export const Summary = () => {
    const dispatch = useDispatch();
    const { userCompanyId } = useAppSelector((state) => state.companies);
    const [supplierData, setSupplierData] = useState([]);

    const columns = [{
        title: "RETAILERS",
        dataIndex: "retailer",
        key: "retailer",
    },{
        title: "PRODUCTS",
        dataIndex: "products",
        key: "products",
    },];

    const getData = async () => {
        const {data} = await authAxios(dispatch).get(`/api/supplier/retailers?company_id=${userCompanyId}`);
        setSupplierData(data);
    }

    if(supplierData.length === 0) {
        getData();
    }
    
    return (
        <>
            <Row className={styles.retailerFont}> 
                <Col span={13}>
                    <IconInfoHolder />
                </Col>
                <Col span={8} className={styles.wrapper}>
                    <Table
                        className={styles.atope}
                        dataSource={supplierData}
                        columns={columns}
                        pagination={false}
                        // calc(-475px + 100vh)
                        // calc(-294px + 100vh)
                        scroll={{ y: 100 }}
                    />
                </Col>
            </Row>
        </>
    );
};