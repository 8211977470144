import { FC, useEffect } from "react";
import styles from "./fileUploadArea.module.scss";
import { ReactComponent as UploadIcon } from "../../../common/icons/upload.svg";
import { ReactComponent as SuccessIcon } from "../../../common/icons/successUpload.svg";
import { ReactComponent as RejectIcon } from "../../../common/icons/rejectIcon.svg";
import Uploader from "../Uploader";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Spin } from "antd";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useActions } from "../../../../hooks/useActions";

const FileUploadArea: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isLoading, sucessfullMessage, error } = useAppSelector(
    (state) => state.upload
  );

  const { cleareSuccesfulUpload } = useActions();

  useEffect(() => {
    if (sucessfullMessage)
      setTimeout(() => dispatch(cleareSuccesfulUpload()), 6000);
  }, [cleareSuccesfulUpload, dispatch, sucessfullMessage]);

  const showInfo = () => {
    if (isLoading) {
      return (
        <div className={styles.loadingWrapper}>
          <Spin />
        </div>
      );
    } else {
      if (!error) {
        if (sucessfullMessage) {
          return (
            <div className={styles.infoAreaSuccess}>
              <div className={styles.infoAreaTop}>
                <SuccessIcon className={styles.successIcon} />
                <div className={styles.uploadText}>{sucessfullMessage}</div>
              </div>
            </div>
          );
        } else {
          return (
            <div className={styles.infoAreaUpload}>
              <div className={styles.infoAreaTop}>
                <UploadIcon className={styles.uploadIcon} />
                <div className={styles.uploadText}>Browse a file to upload</div>
              </div>
              <div className={styles.infoAreaBottom}>
                XLS, XLSX files are supported
              </div>
              <div className={styles.infoAreaBottomSmall}>
                <a href="/PIM_Template_2.xlsx" >Download your template here</a>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className={styles.infoAreaReject}>
            <div className={styles.infoAreaTop}>
              <RejectIcon className={styles.rejectIcon} />
              <div className={styles.uploadText}>{error}</div>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.infoArea}>{showInfo()}</div>
      <Uploader />
    </div>
  );
};

export default FileUploadArea;
