import { Button } from 'antd';
import { FC, useEffect } from 'react';
import styles from './filterSupplier.module.scss';
import FilterControl from '../../../common/components/FilterControl';
import {
    SortedByItemsFilterTypes,
    SortedByItemsFilterValues,
} from '../../constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import {
    getCompaniesNames,
    getDescriptors,
    getItemsGtins,
} from '../../../../api/filter';
import { useActions } from '../../../../hooks/useActions';
import { SelectData } from '../../../common/types';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ReactComponent as DraftItemIcon } from '../../../common/icons/drafts.svg';
import { ReactComponent as TrashIcon } from '../../../common/icons/trash.svg';
import {
    NumberValidationStatus,
    ValidationStatus,
} from '../../../common/constants/status';
import { ModalTabs } from '../../../common/constants/modalTabs';
import { RoutesNames } from "../../../../routes";
import { useNavigate, useParams } from "react-router-dom";

interface FilterOwnProps {
    setOpenModal: (value: boolean) => void;
    countCheckedDraftItems: number;
    totalDraftItemsCount: number;
}

const sortedByValues: SelectData[] = [
    {
        name: SortedByItemsFilterTypes.GTIN,
        value: SortedByItemsFilterValues.GTINS,
    },
    {
        name: SortedByItemsFilterTypes.DESCRIPTORS,
        value: SortedByItemsFilterValues.DESCRIPTORS,
    },
    {
        name: SortedByItemsFilterTypes.STATUS,
        value: SortedByItemsFilterValues.STATUS,
    },
];

const sortedStatusData: SelectData[] = [
    {
        name: ValidationStatus.COMPLETE.toLowerCase(),
        value: NumberValidationStatus.COMPLETE,
    },
    {
        name: ValidationStatus.INCOMPLETE.toLowerCase(),
        value: NumberValidationStatus.INCOMPLETE,
    },
];

const DraftItemsFilter: FC<FilterOwnProps> = ({
    setOpenModal,
    countCheckedDraftItems,
    totalDraftItemsCount,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.auth);
    const {
        cleareDraftItemStore,
        setFilterControlSortedByStatus,
        setIsConfirmDraftItemDeletionModal,
        setIsOpenDraftItemChangeStatusModal,
        setDraftItemsForActivationDeletion,
        setModalActiveTab,
    } = useActions();

    const { draftItemsForActivationDeletion, totalValidItems } = useAppSelector(
        (state) => state.draftItems
    );

    const { sortedByData, isSortedByStatus } = useAppSelector(
        (state) => state.filterControl
    );

    const { userCompanyId, userCompanyType } = useAppSelector((state) => state.companies);
    const navigate = useNavigate();

    useEffect(() => {
        if (userCompanyId) {
            dispatch(getItemsGtins(undefined, userCompanyId as number));
        } else {
            dispatch(getItemsGtins());
        }
    }, [dispatch, userCompanyId]);

    const sortedByValuesWithCompanies = () => {
        if (user?.is_admin) {
            return [
                ...sortedByValues,
                {
                    name: SortedByItemsFilterTypes.COMPANIES,
                    value: SortedByItemsFilterValues.COMPANIES,
                },
            ];
        }

        return sortedByValues;
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.filterControlWrapper}>
                <div className={styles.title}>
                    <DraftItemIcon className={styles.titleIcon} />
                    <div className={styles.titleText}>
                        <div className={userCompanyType === 'wine'?styles.titleTextTopWine:styles.titleTextTop}>{userCompanyType === 'wine'?'Items':'Drafts'}</div>
                        <div
                            className={styles.titleTextBottom}
                        >{userCompanyType === 'wine'?'':`${totalValidItems} complete`}</div>
                    </div>
                </div>
                <FilterControl
                    searchName="Items"
                    cleareActivationDeletionIdes={() =>
                        setDraftItemsForActivationDeletion([])
                    }
                    sortedBy={sortedByValuesWithCompanies()}
                    sortedByData={sortedByData}
                    isSortedByStatus={isSortedByStatus}
                    sortedStatusData={sortedStatusData}
                    dateRangePicker={true}
                    getSortedByData={(sortValue) => {
                        if (sortValue === SortedByItemsFilterValues.GTINS) {
                            if (userCompanyId) {
                                dispatch(
                                    getItemsGtins(
                                        undefined,
                                        userCompanyId as number
                                    )
                                );
                            } else {
                                dispatch(getItemsGtins());
                            }
                        }
                        if (
                            sortValue === SortedByItemsFilterValues.DESCRIPTORS
                        ) {
                            dispatch(getDescriptors());
                        }
                        if (sortValue === SortedByItemsFilterValues.STATUS) {
                            setFilterControlSortedByStatus(true);
                        }
                        if (sortValue === SortedByItemsFilterValues.COMPANIES) {
                            dispatch(getCompaniesNames());
                        }
                    }}
                />
                <div className={styles.countInfoWrapper}>
                    <div className={userCompanyType === 'wine'?styles.countInfoFieldWine:styles.countInfoField}>
                        {!countCheckedDraftItems
                            ? totalDraftItemsCount
                            : countCheckedDraftItems}
                    </div>
                    <div className={styles.countInfoTitle}>
                        {!countCheckedDraftItems ? 'Items' : 'Selected'}
                    </div>
                </div>
            </div>
            {draftItemsForActivationDeletion.length !== 0 ? (
                <div className={styles.buttonsIsCheckGroup}>
                    <div
                        className={userCompanyType === 'wine'?styles.buttonDeleteWine:styles.buttonDelete}
                        onClick={() => {
                            setIsConfirmDraftItemDeletionModal(true);
                        }}
                    >
                        <TrashIcon className={styles.trashIcon} />
                        <div>Delete</div>
                    </div>
                    {userCompanyType !== 'wine'?
                        <Button
                            className={userCompanyType === 'wine'?styles.buttonAddWine:styles.buttonActivate}
                            type="primary"
                            onClick={() => {
                                setIsOpenDraftItemChangeStatusModal(true);
                            }}
                        >
                            <div>Activate</div>
                        </Button>
                    :''}
                    
                </div>
            ) : (
                !user?.is_admin && (
                    <Button
                        type="primary"
                        className={styles.buttonAdd}
                        onClick={() => {
                            cleareDraftItemStore();
                            setModalActiveTab('main');
                            navigate(RoutesNames.ITEM_DETAILS_FORM_WIZARD, {state: {gtin: 'new'}});
                            //navigate(RoutesNames.CREATE_ITEM_WIZARD, {state: {gtin: 'new'}});
                        }}
                    >
                        <div>Create</div>
                    </Button>
                )
            )}
        </div>
    );
};

export default DraftItemsFilter;
