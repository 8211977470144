import { Button, Col, Row, Tabs } from 'antd';
import { Select, Switch } from 'antd';
import InputFieldTitle from '../../../common/components/InputFieldTitle';
import styles from './form.module.scss';
import InputField from '../../../common/components/InputField';
import './form.global.scss';
import ItemFormInputNumberField from '../../../itemForm/components/ItemFormInputNumberField';
import ItemFormInputDateTimeField from '../../../itemForm/components/ItemFormInputDateTimeField';
import { Option } from 'antd/lib/mentions';
import { SelectData } from '../../../common/types';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getDescriptorsList } from '../../../../api/filter';
import { Upload, Modal, notification } from "antd";
import {
    requestDescription,
    requestImages
} from '../../../../api/itemForm';
import { useDispatch } from 'react-redux';
import { getCountries } from '../../../../api/filter';
import MultiFileUploader from '../../../common/components/MultiFileUploader';
import { createDraftItem, editDraftItem } from '../../../../api/items';
import { useActions } from '../../../../hooks/useActions';
import { Popup } from './popup';
import { DownloadOutlined } from '@ant-design/icons';
import { QuestionCircleFilled } from '@ant-design/icons';
import UploadIcon from '../../../common/icons/upload.png';
import { getSizes } from '../../../../api/filter/index';
import { getLanguages } from '../../../../api/filter';
import { getAllergenCodes } from '../../../../api/filter';
import { getLevelOfContainmentCodes } from '../../../../api/filter';
import { getNutrientCodes } from '../../../../api/filter';
import { getMeasurementPrecisions } from '../../../../api/filter';
import { getUnitMeasurements } from '../../../../api/filter';
import { getDerivationCodes } from '../../../../api/filter';
import Allergen from '../../../common/components/Allergen';
import Nutritional from '../../../common/components/Nutritional';
import MultiLanguageInput from '../../../common/components/MultiLanguageInput';
import { convertFilesForRequest } from "../../../../helpers";
import { useLocation } from "react-router-dom";
import barcodeExample from '../../../common/img/barcode_examples.webp';
import { RoutesNames } from "../../../../routes";
import { useNavigate, useParams } from "react-router-dom";

interface FormProps {
  element: any;
  dataForm: any;
  setIsMainFieldsFull: any;
  isLastTab: any;
  isDraft: boolean;
}

interface OptionsProps {
  created_at: string;
  id: number;
  item_field_id: number;
  name: string;
  updated_at: string;
}

interface ItemProps {
  created_at: string;
  id: 6;
  is_file: boolean;
  is_size: boolean;
  is_language: boolean;
  is_multiple: boolean;
  is_elabel: boolean;
  item_tab_id: number;
  key: any;
  mandatory: string | null;
  status: boolean;
  updated_at: string;
  name: string;
  field_type_id: number;
  field_select_options: OptionsProps[];
}

interface MultiFileUploaderState {
    previewVisible: boolean;
    previewImage: string;
    previewTitle: string;
    fileList: FileProps[];
  }

  interface FileProps {
    uid: string;
    name: string;
    url: string;
  }

export const ItemDetailsWizardForm = ({element, dataForm, setIsMainFieldsFull, isLastTab, isDraft, }: FormProps) => {
    const appSel = useAppSelector((state) => state.itemForm);
    const itemFormFields2 = appSel.itemFormFields;
    let itemFormFields = itemFormFields2 !== null ? [{...itemFormFields2[0], title: '1. Basic data'}] : []
    const navigate = useNavigate();

    // const itemFormFields = [
    //     {
    //         id: 1,
    //         name: 'main',
    //         items: '',
    //         title: '1. Basic data'
    //     }
        
    // ]

    const dispatch = useDispatch();
    const { descriptorsList, countriesList } = useAppSelector(
        (state) => state.optionLists
    );

    const { company_id }: any = useAppSelector((state) => state.itemForm);
    const [formData, setFormData] = useState<any>({
        id: null,
        gtin: null,
        description: null,
        trade_item_descriptor_id: null,
        brand: null,
        target_market_id: null,
        'global-product-classification-code': null
      });

        const [formDataError, setFormDataError] = useState<any>({});
        const [dinamicFormData, setDinamicFormData] = useState<any>([]);
        const [dinamicFormDataError, setDinamicFormDataError] = useState<any>([]);
        const [mandatoryDinamicField, setMandatoryDinamicField] = useState({});
        const [mandatoryMetaDataFields, setMandatoryMetaDataFiealds] = useState({});
        const [chosenImage, setChosenImage] = useState(0);
        const location = useLocation();
        const [fileUrl, setFileUrl] = useState<any>();
        const { popup }: any = useAppSelector((state) => state.itemForm);
        const { itemDescription }: any = useAppSelector((state) => state.itemForm);
        const { itemImages }: any = useAppSelector((state) => state.itemForm);
        const [uploaderData, setUploaderData] = useState<MultiFileUploaderState>({
            previewVisible: false,
            previewImage: "",
            previewTitle: "",
            fileList: [],
        });
        const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
        const { user }: any = useAppSelector((state) => state.auth);
        const selectedTab: any = useAppSelector(
            (state) => state.modals.draftItemsFormModalActiveTab
            );
        const [gtinError, setGtinError] = useState<string>('');
        const [metaData, setMetaData] = useState<any[]>([]);
        const [metaError, setMetaError] = useState<boolean>(false);
        const [newLink, setNewLink] = useState('');
        const [newLinkError, setNewLinkError] = useState(false);
        const { setModalActiveTab } = useActions();
        const [itemAllergens, setItemAllergens] = useState<any[]>([]);
        const [itemNutritionals, setItemNutritionals] = useState<any>([]);
        const { userCompanyType } = useAppSelector((state) => state.companies);
        const [step, setStep] = useState<number>(1);

        useEffect(() => {
            if (!metaData) return;
            if (typeof metaData[chosenImage]?.file !== 'string') {
              setFileUrl('');
          }
      }, [chosenImage]);

        useEffect(() => {
            if (!dataForm?.file_links?.length) return;
            const data: any = [];
            dataForm.file_links.forEach((elem: any) => {
              const fieldsObj: any = {};
              elem.fields.forEach((e: any) => {
                fieldsObj[e.item_field.id] = e.value;
            });
              data.push({
                file: elem.file,
                fields: fieldsObj,
            });
          });
            setMetaData(data);
        }, [dataForm]);

        useEffect(() => {
            if (!element) return;

            if (element?.name === 'digital assets') {
              const data: any = {};

              element.items.forEach((elem: any) => {
                if (elem.is_file) {
                  data[elem.id] = '';
              }
          });

              const dataFormObj = { ...formData };
              const result: any = [];
              dataFormObj?.file_links?.forEach((elem: any) => {
                if (elem.fields.length) {
                  const fieldsObj: any = {};
                  elem.fields.forEach((e: any) => {
                    if (e.item_field.id) {
                      fieldsObj[e.item_field.id] = e.value;
                  }
              });
                  result.push({
                    file: elem.file,
                    fields: { ...fieldsObj },
                });
              } else {
                  result.push({
                    file: elem.file,
                    fields: { ...data },
                });
              }
          });
              setMetaData(result);
          }
      }, [element, formData]);

        const handleCancel = () => {
            setIsModalVisible(false)
        }
            //setUploaderData({ ...uploaderData, previewVisible: false });
        
        const handleRequestDescription = () => {
            const data = { ...formData }
            dispatch(requestDescription(data.gtin))
        }

        const handleRequestImages = () => {
            const data = { ...formData }
            dispatch(requestImages(data.gtin))
        }

        const handleModalOpen = () => {
            setIsModalVisible(true)
        }

        const getGPCCodes = (element) => {
          for(var i in element.items) {
            if(element.items[i].key === 'global-product-classification-code') {
              return element.items[i].field_select_options
            }
          }
        }

        const handleClear = () => {
            const dinamicData = { ...dinamicFormData };
            element?.items.forEach((elem: any) => {
              dinamicData[elem.key] = null;
          });
            const data = { ...formData };
            if (element?.name === 'main') {
              data.gtin = null;
              data.description = null;
              data.trade_item_descriptor_id = null;
              data.brand = null;
              data.target_market_id = null;
              data['global-product-classification-code'] = null;
          }
          if (element?.name === 'measurements') {
              data.quantity = null;
          }
          if (element?.name === 'digital assets') {
              data.file_links = [];
          }
          setDinamicFormData(dinamicData);
          setFormData(data);
      };

      useEffect(() => {
        if (!dataForm) {
          setIsMainFieldsFull(false);
      } else {
          setIsMainFieldsFull(true);
      }
    }, [dataForm]);

      useEffect(() => {
        if (!element) return;
        const data: any = {};
        element?.items?.forEach((elem: any) => {
          if (
            elem.mandatory === 'Yes' &&
            +elem.field_type_id !== 2 &&
            !elem.is_file
            ) {
            data[elem.key] = true;
    }
    });
        setMandatoryDinamicField(data);

        const metaData: any = {};
        element?.items?.forEach((elem: any) => {
          if (
            elem.mandatory === 'Yes' &&
            +elem.field_type_id !== 2 &&
            elem.is_file
            ) {
            metaData[elem.id] = true;
    }
    });
        setMandatoryMetaDataFiealds(metaData);
        const languageData: any = { ...dinamicFormData };
        var isLanguageKeys: Array<string> = [];
        element?.items?.forEach((elem: ItemProps) => {
          const item = dataForm?.item_forms?.find((elemDraft: any) => {
            if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
              return elemDraft.item_field.key === elem.key  
          }

      });
          languageData[elem.key] = item ? item.value : null;
         //Keep language field keys
            if(item) {
              if(item.item_field.is_language) {
                isLanguageKeys.push(elem.key);  
            }

        }
    });

        languageData['isLanguageKeys'] = isLanguageKeys;
        setDinamicFormData(languageData);
    }, [element]);

    // inserts STATIC values from an open element
      useEffect(() => {
        if (!dataForm || !descriptorsList || !countriesList) return;
        let data: any = { ...formData };
        let dataErr: any = {};
        let dinamicData: any = { ...dinamicFormData };
        data.id = dataForm.id;
        if (element?.name === 'main') {
          data.gtin = dataForm.gtin;
          data.description = dataForm.description;
          const itemDescriptor: any = descriptorsList.find(
            (elem: any) => +elem.value === +dataForm.trade_item_descriptor_id
            );
          data.trade_item_descriptor_id = itemDescriptor
          ? itemDescriptor?.value + ''
          : null;
          data.brand = dataForm.brand;
          const targetMarketId: any = countriesList.find(
            (elem: any) => +elem.value === +dataForm.target_market_id
            );
          data.target_market_id = targetMarketId
          ? targetMarketId?.value + ''
          : null;
      }
      if (element?.name === 'measurements') {
          data.quantity = dataForm.quantity;
          dataErr.quantity = false;
      }
      if (element?.name === 'digital assets') {
          data.file_links = dataForm?.file_links;
      }
      dataForm?.item_forms?.forEach((elem: any) => {
          if(elem.item_field !== undefined && elem.item_field !== null) {
            dinamicData[elem.item_field.key] = elem.value;  
        }

    });

    //const languageData: any = { ...dinamicFormData };
    var isLanguageKeys: Array<string> = [];
    element?.items?.forEach((elem: ItemProps) => {
      const item = dataForm?.item_forms?.find((elemDraft: any) => {
        if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
          return elemDraft.item_field.key === elem.key  
        }

      });
      dinamicData[elem.key] = item ? item.value : null;
     //Keep language field keys
        if(item) {
          if(item.item_field.is_language) {
            isLanguageKeys.push(elem.key);  
        }

    }
    });

    dinamicData['isLanguageKeys'] = isLanguageKeys;
    setFormDataError(dataErr);
    setDinamicFormData(dinamicData);
    setFormData(data);

    //Allergens
    if(dataForm.item_allergens !== undefined && dataForm.item_allergens.length > 0) {
      setItemAllergens(dataForm.item_allergens)
    }

    //Nutritional
    if(dataForm?.item_nutrients !== undefined && dataForm?.item_nutrients.length > 0) {
      const nutrients: any = [];
    
      dataForm.item_nutrients.map((value: any) => {
              nutrients[value.nutrientCode] = {
                nutrientName: value.nutrientName, 
                nutrientCode: value.nutrientCode, 
                qtyMeasurementPrecisionCode: value.qtyMeasurementPrecisionCode,
                qtyMeasurementPrecisionValue: value.qtyMeasurementPrecisionValue, 
                unitMeasure: value.unitMeasure, 
                percentMeasurementPrecisionCode: value.percentMeasurementPrecisionCode, 
                percentMeasurementPrecisionValue: value.percentMeasurementPrecisionValue, 
                derivationCode: value.derivationCode
          }
      });
      setItemNutritionals(nutrients);
    }
    
    }, [dataForm, element, countriesList, descriptorsList]);

      useEffect(() => {
        dispatch(getDescriptorsList());
        dispatch(getCountries());
        dispatch(getSizes());
    }, []);

    // inserts DINAMIC values from an open element
      useEffect(() => {
        if (!element || !dataForm) return;
        const data: any = { ...dinamicFormData };
        var isLanguageKeys: Array<string> = [];
        element?.items?.forEach((elem: ItemProps) => {
          const item = dataForm?.item_forms?.find((elemDraft: any) => {
            if(elemDraft.item_field !== undefined && elemDraft.item_field !== null) {
              return elemDraft.item_field.key === elem.key  
          }

      });
          data[elem.key] = item ? item.value : null;
          //Keep language field keys
          if(item) {
              if(item.item_field.is_language) {
                isLanguageKeys.push(elem.key);  
            }

        }
    });
        data['isLanguageKeys'] = isLanguageKeys;
        setDinamicFormData(data);
    }, [element, dataForm]);

      const handleChange = (name: string, value: any) => {
        let data = { ...dinamicFormData };
        data[name] = value;
        setDinamicFormData(data);
    };

    // check if a field is filled at fill time
    useEffect(() => {
        const errors: any = { ...formDataError };
        const errorDinamic = { ...dinamicFormDataError };
        for (let prop in formData) {
          if (formData[prop]) {
            errors[prop] = false;
        }
    }
    for (let prop in mandatoryDinamicField) {
      if (dinamicFormData[prop]) {
        errorDinamic[prop] = false;
      }
    }

    //Set mandatory for Allergens
    if (itemAllergens !== undefined && itemAllergens !== null && itemAllergens.length > 0) {
      errorDinamic['allergen-codes'] = false;
    }

    setDinamicFormDataError(errorDinamic);
    setFormDataError(errors);
    }, [formData, dinamicFormData]);



    useEffect(() => {
        if (!element || !metaData) return;
        if (element.name === 'digital assets') {
          const errorMetaData: any = { ...mandatoryMetaDataFields };
          const data = JSON.parse(JSON.stringify(metaData));
          let isValid = true;
          data.forEach((elem: any) => {
            for (let prop in elem.fields) {
              if (typeof elem.file === 'string') {
                for (let p in errorMetaData) {
                  if (prop === p) {
                    if (!elem.fields[prop]) {
                      isValid = false;
                  }
              }
          }
      }
    }
    });
          if (isValid) {
            setMetaError(false);
        }
    }
    }, [element, metaData]);

    const handleSubmit = () => {
        let isValid = true;
        const errors = { ...formDataError };

        if (element?.name === 'main') {
          if (!/^[0-9]+$/.test(formData.gtin)) {
            isValid = false;
            errors.gtin = true;
        }
        if (!formData.gtin) {
            isValid = false;
            errors.gtin = true;
        }
        if (!formData.description) {
            isValid = false;
            errors.description = true;
        }
        if (!formData.trade_item_descriptor_id) {
            isValid = false;
            errors.trade_item_descriptor_id = true;
        }
        if (!formData.brand) {
            isValid = false;
            errors.brand = true;
        }
        if (!formData.target_market_id) {
            isValid = false;
            errors.target_market_id = true;
        }
    }
    if (element?.name === 'measurements') {
      if (!formData.quantity) {
        isValid = false;
        errors.quantity = true;
      }
    }
    if (element?.name === 'digital assets') {
      if (newLinkError) {
        isValid = false;
      }
      const errorMetaData: any = { ...mandatoryMetaDataFields };
      const data = JSON.parse(JSON.stringify(metaData));
      data.forEach((elem: any) => {
          for (let prop in elem.fields) {
            if (typeof elem.file === 'string') {
              for (let p in errorMetaData) {
                if (prop === p) {
                  if (!elem.fields[prop]) {
                    isValid = false;
                    setMetaError(true);
                  }
                }
              }
            }
          }
      });
    }
    setFormDataError(errors);

    const errorDinamicData: any = { ...mandatoryDinamicField };
    // console.log('errorDinamicData', errorDinamicData);
    const errorData = { ...dinamicFormDataError };
    for (let prop in errorDinamicData) {
      if (!formData[prop]) {
          isValid = false;
          errorData[prop] = true;
      }
    }
    setDinamicFormDataError(errorData);
    
    if(element?.name == 'Ingredients') {
      const errorDinamicData: any = { ...mandatoryDinamicField };
      const errorData = { ...dinamicFormDataError };

      for (let prop in errorDinamicData) {
        
        if (!dinamicFormData[prop]) {
          isValid = false;
          errorData[prop] = true;
        } else {
          const languageData = typeof dinamicFormData[prop] === 'string'?JSON.parse(dinamicFormData[prop]):dinamicFormData[prop];
          languageData.map((line) => {
            if(line['languageText'] !== '' && line['languageCode'] === '' || 
              line['languageText'] === '' && line['languageCode'] !== '' ||
              line['languageText'] === '' && line['languageCode'] === '') {
              isValid = false;
              errorData[prop] = true;
            }
          })
        }
      }
      setDinamicFormDataError(errorData);
    }

    if(selectedTab == 'allergen') {
      const errorDinamicData: any = { ...mandatoryDinamicField };
      const errorData = { ...dinamicFormDataError };

      if (itemAllergens !== undefined && itemAllergens !== null && itemAllergens.length == 0) {
        isValid = false;
        errorData['allergen-codes'] = true;
      }

      setDinamicFormDataError(errorData);
    }
    
    if (isValid) {
      const converFormData: any = { ...formData };
      delete converFormData.file_links;
      delete dinamicFormData.isLanguageKeys;

      const requestData = {
        ...converFormData,
        files: convertFilesForRequest(metaData),
        item_forms: JSON.stringify({ ...dinamicFormData }),
        item_allergens: JSON.stringify({ ...itemAllergens }),
        item_nutrients: JSON.stringify({ ...itemNutritionals }),
    };
    if (!dataForm) {
        if (element?.name === 'main') {
          dispatch(createDraftItem(company_id, requestData, selectedTab));
          dispatch(setModalActiveTab("main"))
          
          navigate(RoutesNames.ITEM_DETAILS_FORM, {state: {gtin: 'new'}});
      } else {
          dispatch(
            editDraftItem(company_id, requestData, selectedTab, isLastTab)
          );
      }
    } else {
        dispatch(
          editDraftItem(company_id, requestData, selectedTab, isLastTab)
        );
    }
    }
    // }
    };

    useEffect(() => {
        if (!formData.gtin) return;
        if (!/^[0-9]+$/.test(formData.gtin)) {
          setGtinError('GTIN is not valid');
      } else {
          setGtinError('');
      }
    }, [formData.gtin]);

    const setMetaDatavalues = (id: number, value: any) => {
        if (!fileUrl) return;
        const data = [...metaData];
        const elem = data.find((e: any) => {
          return e.file === fileUrl;
        });

        if(elem !== null && elem !== undefined) {
          elem.fields[id] = value;
        }

        setMetaData(data);
    };

    const getMetaValue = (id: number) => {
        if (!fileUrl) {
          return '';
      } else {
          const data = [...metaData];
          const elem = data.find((e: any) => {
            return e.file === fileUrl;
        });
          return elem?.fields?.[id];
      }
    };

    const setNewLinkFunc = (e: any) => {
        setNewLink(e.target.value);
        let data = JSON.parse(JSON.stringify(metaData));
        data.push({
          file: e.target.value,
          fields: {},
      });

        setMetaData(data);
    };

    useEffect(() => {
        if (newLink.length > 255) {
          setNewLinkError(true);
      } else {
          setNewLinkError(false);
      }
    }, [newLink]);

    const onChangeTab = (key) => {
          dispatch(
              setModalActiveTab(
                key
              )
          );
      
    }

    return (
        <>
            <Row>
                <Col span={24} className={userCompanyType === 'wine'?'wine':''}>
                    <Tabs defaultActiveKey="main" activeKey={selectedTab} onChange={onChangeTab}>
                        {itemFormFields !== null && itemFormFields !== undefined? 
                          itemFormFields.map((element: any) => {
                     return <Tabs.TabPane tab={element.title.toLowerCase()} key={element.name} >
                              <form className="itemFormFieldDinamicForm" style={{background: '#ffffff', borderRadius: '8px', padding: '25px'}}>
                                <Popup
                                  status="success"
                                  className={popup === 'success' ? styles.active : ''}
                                />
                                <Popup
                                  status="error"
                                  className={popup === 'error' ? styles.active : ''}
                                />
                                <Popup
                                  status="gtinError"
                                  className={popup === 'gtinError' ? styles.active : ''}
                                />
                                {element?.name === 'main' && (
                                  <>
                                    <Row>
                                        <Col xs={{span: 12}}>
                                            <h1>Create your product with just a few steps</h1>
                                            <Row justify="start" className={styles.fieldWrapper}>
                                            <Col className={styles.fieldName} span={8}>GTIN / Barcode</Col>
                                            <Col xs={{span: 16}} lg={{span: 12}}>
                                                <InputField
                                                maxLength={14}
                                                value={formData.gtin}
                                                className={styles.field}
                                                disabled={user.is_admin || !isDraft || location.state == undefined || location.state == null}
                                                placeholder="GTIN"
                                                required={false}
                                                errorMessage={
                                                    gtinError
                                                    ? gtinError
                                                    : formDataError.gtin
                                                    ? 'This field is required'
                                                    : ''
                                                }
                                                onChange={(e: any) => {
                                                    const data = { ...formData };
                                                    data.gtin = e.target.value;
                                                    setFormData(data);
                                                }}
                                                onFocus={() => {setStep(1)}}
                                                />
                                            </Col>
                                            {step === 1 ?
                                            <Col lg={{span: 4}} style={{height: '2px', background: '#EEE', marginTop: '20px'}}>
                                                
                                            </Col> : '' }
                                            </Row>
                                            <Row justify="start" className={styles.fieldWrapper}>
                                            <Col className={styles.fieldName} span={8}>Description</Col>
                                            <Col xs={{span: 16}} lg={{span: 12}}>
                                                <InputField
                                                    value={formData.description}
                                                    className={styles.field}
                                                    errorMessage={
                                                    formDataError.description ? 'This field is required' : ''
                                                    }
                                                    disabled={user.is_admin || !isDraft ? true : false}
                                                    placeholder="Description"
                                                    required={false}
                                                    onChange={(e: any) => {
                                                        const data = { ...formData };
                                                        data.description = e.target.value;
                                                        setFormData(data);
                                                    }}
                                                    onFocus={() => {setStep(2)}}
                                                />
                                            </Col>
                                            {step === 2 ?
                                            <Col lg={{span: 4}} style={{height: '2px', background: '#EEE', marginTop: '20px'}}>
                                                
                                            </Col> : '' 
                                            }
                                            </Row>
                                            <Row className={styles.fieldWrapper}>
                                                <Col className={styles.fieldName} span={8}>Level</Col>
                                                <Col xs={{span: 16}} lg={{span: 12}}>
                                                    <InputFieldTitle
                                                        title=""
                                                        required={false}
                                                        errorMessage={
                                                        formDataError.trade_item_descriptor_id
                                                            ? 'This field is required'
                                                            : ''
                                                        }
                                                    >
                                                        <Select
                                                        size="middle"
                                                        className={styles.selectField}
                                                        disabled={user.is_admin || !isDraft ? true : false}
                                                        value={formData.trade_item_descriptor_id}
                                                        placeholder="Level"
                                                        // onBlur={() =>
                                                        //     setFieldTouched('trade_item_descriptor_id')
                                                        // }
                                                        onChange={(value: any) => {
                                                            const data = { ...formData };
                                                            data.trade_item_descriptor_id = value;
                                                            setFormData(data);
                                                        }}
                                                        onFocus={() => {setStep(3)}}
                                                        >
                                                        {descriptorsList.map((sortOption: SelectData) => (
                                                            <Option key={sortOption.name} value={`${sortOption.value}`}>
                                                            {sortOption.name}
                                                            </Option>
                                                        ))}
                                                        </Select>
                                                    </InputFieldTitle>
                                                </Col>
                                                {step === 3 ?
                                                <Col lg={{span: 4}} style={{height: '2px', background: '#EEE', marginTop: '20px'}}>
                                                    
                                                </Col> : '' 
                                                }
                                            </Row>
                                            <Row justify="start" className={styles.fieldWrapper}>
                                                <Col className={styles.fieldName} span={8}>Brand</Col>
                                                <Col xs={{span: 16}} lg={{span: 12}}>
                                                    <InputField
                                                    value={formData.brand}
                                                    className={styles.field}
                                                    disabled={user.is_admin || !isDraft ? true : false}
                                                    placeholder="Brand"
                                                    required={false}
                                                    errorMessage={
                                                        formDataError.brand ? 'This field is required' : ''
                                                        }
                                                        onChange={(e: any) => {
                                                            const data = { ...formData };
                                                            data.brand = e.target.value;
                                                            setFormData(data);
                                                        }}
                                                        onFocus={() => {setStep(4)}}
                                                    />
                                                </Col>
                                                {step === 4 ?
                                                <Col lg={{span: 4}} style={{height: '2px', background: '#EEE', marginTop: '20px'}}>
                                                    
                                                </Col> : '' 
                                                }
                                            </Row>
                                            <Row justify="start" className={styles.fieldWrapper}>
                                                <Col className={styles.fieldName} span={8}>Target Market</Col>
                                                <Col xs={{span: 16}} lg={{span: 12}}>
                                                <InputFieldTitle
                                                    title=""
                                                    required={false}
                                                    errorMessage={
                                                    formDataError.target_market_id ? 'This field is required' : ''
                                                    }
                                                >
                                                    <Select
                                                    size="middle"
                                                    className={styles.selectField}
                                                    disabled={user.is_admin || !isDraft ? true : false}
                                                    placeholder="Target Market"
                                                    value={formData.target_market_id}
                                                    onChange={(value: any) => {
                                                        const data = { ...formData };
                                                        data.target_market_id = value;
                                                        setFormData(data);
                                                    }}
                                                    onFocus={() => {setStep(5)}}
                                                    >
                                                    {countriesList.map((sortOption: SelectData) => (
                                                        <Option key={sortOption.name} value={`${sortOption.value}`}>
                                                        {sortOption.name}
                                                        </Option>
                                                    ))}
                                                    </Select>
                                                </InputFieldTitle>
                                                </Col>
                                                {step === 5 ?
                                                <Col lg={{span: 4}} style={{height: '2px', background: '#EEE', marginTop: '20px'}}>
                                                    
                                                </Col> : '' 
                                                }
                                            </Row>
                                            <Row justify="start" className={styles.fieldWrapper}>
                                                <Col className={styles.fieldName} span={8}>Global Product Classification Code</Col>
                                                <Col xs={{span: 16}} lg={{span: 12}}>
                                                  <InputFieldTitle
                                                    title=""
                                                    required={false}
                                                    errorMessage={
                                                      formDataError.target_market_id ? 'This field is required' : ''
                                                    }
                                                  >
                                                    <Select
                                                      size="middle"
                                                      className={styles.selectField}
                                                      disabled={user.is_admin || !isDraft ? true : false}
                                                      placeholder="Select GPC Code"
                                                      value={formData['global-product-classification-code']}
                                                      onChange={(value: any) => {
                                                        const data = { ...formData };
                                                        data['global-product-classification-code'] = value;
                                                        setFormData(data);
                                                      }}
                                                      onFocus={() => {setStep(6)}}
                                                      >
                                                      {getGPCCodes(element).map((sortOption: SelectData) => (
                                                          <Option key={sortOption.name} value={`${sortOption.name}`}>
                                                          {sortOption.name}
                                                          </Option>
                                                      ))}
                                                    </Select>
                                                  </InputFieldTitle>
                                                </Col>
                                                {step === 6 ?
                                                <Col lg={{span: 4}} style={{height: '2px', background: '#EEE', marginTop: '20px'}}>
                                                    
                                                </Col> : '' 
                                                }
                                            </Row>
                                        </Col>
                                        <Col xs={{span: 12}} style={{height: '100%', background: '#EEE', padding: '1rem 2rem', minHeight: '500px'}}>
                                            <QuestionCircleFilled style={{position: 'absolute', left: '-16px', top: '-16px', fontSize: '32px'}} />
                                            {step === 1 ?
                                            <>
                                                <h3>What's your barcode?</h3>
                                                <p>The global number that uniquely identifies a product for the item, case, pallet, etc. (physical or non-physical)</p>
                                                <p>This is the barcode of your product, known as GTIN (Global Trade Item Number) in the GS1 standards</p>
                                                <p>This would ordinarily be issued by your GS1 MO</p>
                                                <p>Please introduce the numbers from left to right</p>
                                                <p>Here you can find some examples:</p>
                                                <p style={{textAlign: 'center'}}>
                                                    <img src={barcodeExample} />
                                                </p>
                                            </>
                                            :
                                            <></>
                                            }
                                            {step === 2 ?
                                            <>
                                                <h3>Describe your item</h3>
                                                <p>An understandable, unique and useable description of a product using a combination of key elements such as Brand Name, Sub-Brand (if applicable), Functional Name, Variant, and Net Content. The description should be meaningful for the Retailers to manage the product through their business and describe the product to their consumers e.g. Brand, flavour, scent etc</p>
                                            </>
                                            :
                                            <></>
                                            }
                                            {step === 3 ?
                                            <>
                                                <h3>Level of the current item</h3>
                                                <p>Also referred to as Product Type, this describes the specific hierarchy level of your item and is used to communicate the structure of product hierarchy. For example, a common three-tier hierarchy (from largest to smallest) is Pallet, Case, Each</p>
                                                <p>Are you creating a crate, a pallet or maybe the product itself? Depending on the Product Type the system will ask you for some specific data</p>
                                            </>
                                            :
                                            <></>
                                            }
                                            {step === 4 ?
                                            <>
                                                <h3>Define the brand of your product</h3>
                                                <p>The name provided by the brand owner that is intended to be recognised by the consumer</p>
                                            </>
                                            :
                                            <></>
                                            }
                                            {step === 5 ?
                                            <>
                                                <h3>Target Market Code</h3>
                                                <p>The code representing the country where the product/service is intended to be sold. </p>
                                            </>
                                            :
                                            <></>
                                            }
                                        </Col>
                                    </Row>
                                  </>
                                )}
      </form>
      {user.is_admin || !isDraft ? (
        <div className={styles.wrapButton}></div>
      ) : (
        <div className={styles.wrapButton}>
          <div className={styles.clearButton} onClick={handleClear}>
            Clear
          </div>
          <div className={userCompanyType === 'wine'?styles.saveButtonWine:styles.saveButton} onClick={handleSubmit}>
            Save
          </div>
        </div>
      )}
                            </Tabs.TabPane>
                        }):''}
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};

export default ItemDetailsWizardForm;